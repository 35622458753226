







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FilterType } from '@/types/commmon.types'

@Component
export default class FilterTags extends Vue {
  @Prop({ type: Array, default: [] })
  filters!: FilterType[]

  @Prop({ type: Boolean })
  isAnimationEnd!: boolean

  get moreFilters(): object[] {
    return this.filters.slice(1, this.filters.length)
  }

  deleteFilter(index: number): void {
    this.$emit('delete-filter', index)
  }
}
