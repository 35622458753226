



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AccrualsListItemType } from '@/types/accruals'

@Component
export default class AccrualsDetail extends Vue {
  @Prop({ type: Object })
  accrual!: AccrualsListItemType

  showMemberInfo() {
    this.$emit('show-member-info', this.accrual.UF_USER_ID)
  }
}
