










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { OrdersListItemType } from '@/types/orders'

export type OrderListType = {
  product_name: string
  product_price: string
  product_count: string
}

@Component
export default class OrdersDetail extends Vue {
  @Prop({ type: Object })
  order!: OrdersListItemType

  setOrderList(order: OrderListType): string {
    return `${order.product_name} ${order.product_price} x ${order.product_count} шт.`
  }

  showMemberInfo() {
    this.$emit('show-member-info', this.order.UF_USER_ID)
  }
}
