































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { DcInput } from 'dc-ui-library/.dist/types/packages/components'

@Component
export default class InputCommon extends Vue {
  $refs!: {
    input: DcInput
  }

  @Model('input')
  value!: string

  @Prop({ type: String })
  label?: string

  @Prop({ type: String })
  type?: string

  @Prop({ type: Boolean, default: true })
  validateEvent!: boolean

  focus = false
  passwordHide = true

  dispatch(componentName: string, eventName: string, params: string[]): void {
    let parent = this.$parent || this.$root
    let name = parent.$options.name

    while (parent && (!name || name !== componentName)) {
      parent = parent.$parent

      if (parent) {
        name = parent.$options.name
      }
    }

    if (parent) {
      parent.$emit.apply(parent, [eventName, ...params])
    }
  }

  handleTextInput(inputValue: string): void {
    this.$emit('input', inputValue)

    if (this.validateEvent) {
      this.dispatch('ElFormItem', 'el.form.change', [this.value])
    }
  }

  handleClickOnLabel(): void {
    const input = this.$refs.input.$el as HTMLLabelElement
    if (input) {
      input.focus()
    }
    this.focus = true
  }

  handlePasswordHide(): void {
    this.passwordHide = !this.passwordHide
  }

  focusChange(type: string): void {
    if (type === 'focus') {
      this.focus = true
      this.$emit('focus')
    } else {
      this.focus = false
      this.$emit('blur')
    }
  }
}
