











































import { Component, Vue, Model, Prop } from 'vue-property-decorator'

@Component
export default class DialogInfo extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: String })
  icon!: string

  @Prop({ type: String, default: 'primary' })
  type!: string

  @Prop({ type: Function })
  callback?: Function

  handleButtonClick(): void {
    if (typeof this.callback === 'function') {
      this.callback()
    }
    this.handleCloseDialog()
  }

  handleCloseDialog(): void {
    this.$emit('change', false)
  }
}
