















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MembersListItemType } from '@/types/members'

@Component
export default class MembersDetail extends Vue {
  @Prop({ type: Object })
  member!: MembersListItemType

  handleShopInfoShow(member: MembersListItemType): void {
    this.$emit('shop-info-show', member.SHOP_ID)
  }
}
