























































import { Vue, Component, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import { CodesApplyFiltersType } from '@/types/codes'

@Component({
  components: { InputCommon, DatePickerCommon, SelectCommon },
})
export default class TableHeader extends Vue {
  @Prop({ type: String })
  type!: string

  @Prop({ type: String })
  columnName!: string

  @Prop({ type: String })
  inputLabel!: string

  @Prop({ type: String })
  label!: string

  @Prop({ type: Array })
  options?: []

  value = null

  dates = {
    dateFrom: '',
    dateTo: '',
  }

  get datesFilters() {
    const filters: CodesApplyFiltersType['dateFilters'] = []

    Object.entries(this.dates).forEach(([key, value]) => {
      if (value.length) {
        filters.push({
          target: key === 'dateFrom' ? '>=DATE_ACTIVATE' : '<DATE_ACTIVATE',
          data: value,
        })
      }
    })

    return filters
  }

  handleAddFilter(): void {
    this.$emit('add-filter', {
      data: this.value,
      target: this.columnName,
      dateFilters: this.datesFilters,
    })
  }
}
